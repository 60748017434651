<script lang="ts" setup>
import {computed, nextTick, onActivated, ref, watch} from 'vue';
import { useRouter, useRoute} from 'vue-router';
import {handleApiRequest} from "@/api/handleApiRequest";
import {getSupportAgentProfile, getNewSupportAgentProfile, postSupportAgentProfile} from '@/api/endpoints/supportAgentWeb';
import {i18n} from '@/plugins/i18n';
import {AccountType} from "@/constants/enums";
import {useQuasar} from 'quasar';
import type { SupportAgentProfile } from "@/api/models/supportUser";
import type { VueTelInputData} from "@/types/phoneTelNumberInputTypes";
import Toggle from "@/components/form/Toggle.vue";
import Dropdown, { type DropdownItem } from "@/components/Dropdown.vue";
import { getAntiForgeryToken } from "@/api/endpoints/userWeb";

// #region External State

const $q = useQuasar()
const router = useRouter()
const $route = useRoute()


// #endregion External State


// #region State

const emptyRepObject = { } as SupportAgentProfile;

const form = ref()
const isLoading = ref(true)
const supportAgent = ref({ ...emptyRepObject })
const phoneTelNumberInputModel = ref({
  value: '',
  phoneObject: {} as VueTelInputData
});
const isManager = ref(false)
const selectedAccountStatus = ref<DropdownItem>();

const userDeactivated = ref(false);

const accountStatuses = computed(() => {
  const array: Array<DropdownItem> = [{
    label: i18n.global.t('account_status_active'),
    value: 'active',
    disabled: false,
    params: {}
  }, {
    label: i18n.global.t('account_status_inactive'),
    value: 'inactive',
    disabled: false,
    params: {}
  }];

  return array;
});

const buildFormStateObject = () => ({
  supportAgent: { ...supportAgent.value },
  phoneNumber: phoneTelNumberInputModel.value?.phoneObject?.nationalNumber,
  isManager: isManager.value,
  selectedAccountStatus: selectedAccountStatus.value
})

const initialFormState = ref(buildFormStateObject())

// #endregion State

// #region Computeds

const agentId = computed(() => {
  const id = $route.params.userId as string;
  if (!id) return 0;
  return parseInt(id, 10);
})

const isCreate = computed(() => {
  return agentId.value === 0;
})

const derivedCountryCode = computed(() => {
  return 'US';
})

const disableFormFields = computed(() => {
  return supportAgent.value.isDeactivated || userDeactivated.value === true;
})

const disableEmail = computed(() => {
  return !supportAgent.value.canEditEmail && !isCreate.value;
})

const isFormDirty = computed(() => {
  const x = JSON.stringify(buildFormStateObject())
  const y = JSON.stringify(initialFormState.value)
  return x !== y;
});


// #endregion Computeds

// #region Watchers
watch(() => selectedAccountStatus.value?.value, (status) => {
  userDeactivated.value = status === 'inactive';
});

// #endregion Watchers

// #region Handlers

const onSubmit = async () => {
  $q.loading.show()

  const accountStatusChanged = selectedAccountStatus.value?.value !== initialFormState.value.selectedAccountStatus?.value;
  const isDeactivated = selectedAccountStatus.value?.value === 'inactive';
  supportAgent.value.isDeactivated = isDeactivated;
  const isValid = await form.value.validate()
  if (isValid) {
    const upsertRepObj = {
      ...supportAgent.value,
      phoneCountryCode: phoneTelNumberInputModel.value.phoneObject.countryCallingCode,
      phone: phoneTelNumberInputModel.value.phoneObject.nationalNumber,
      permissions: supportAgent.value.permissions.map(p => ({ ...p, selected: p.name == "CustomerSupportRep" || (p.name == "CustomerSupportManager" && isManager.value) })),
      accountStatusChanged: accountStatusChanged
    }

    await getAntiForgeryToken();
    const response = await handleApiRequest(() => postSupportAgentProfile(upsertRepObj));

    if (response?.data?.result?.userId) {
      let message;
      if (accountStatusChanged) {
        message = isDeactivated ? 'support_profile_deactivated' : 'support_profile_reactivated';
      } else {
        message = isCreate.value ? 'support_profile_created' : 'support_profile_updated';
      }

      $q.notify({
        message: i18n.global.t(message, [supportAgent.value.firstName, supportAgent.value.lastName]).toString(),
        type: 'message'
      });

      const supportAgentId = response.data.result.userId;

      router.push({
        name: 'EditSupportAgent',
        params: {
          userId: supportAgentId
        }
      });

      await populateAgentData(supportAgentId);
    }
    else {
      const message = response?.data?.errorMessage || i18n.global.t('error_unexpected_occured').toString();
      $q.notify({
        message: message,
        type: 'warning'
      });
    }
  }

  $q.loading.hide()
}

const onCancel = () => {
  router.go(-1);
}

const onGoBack = () => {
  router.go(-1);
}

const populateAgentData = async (supportAgentId : number) => {
  isLoading.value = true;

  supportAgent.value = { ...emptyRepObject };
  selectedAccountStatus.value = {} as DropdownItem;

  const getSupportAgentProfileResponse = supportAgentId === 0
    ? await handleApiRequest(() => getNewSupportAgentProfile(AccountType.CustomerSupport))
    : await handleApiRequest(() => getSupportAgentProfile(supportAgentId));

  if (getSupportAgentProfileResponse?.data?.result) {
    const getSupportAgentResult = getSupportAgentProfileResponse.data.result as SupportAgentProfile;

    const managerPermission = getSupportAgentResult.permissions.find(p => p.name === 'CustomerSupportManager');
    isManager.value = managerPermission?.selected || false;

    supportAgent.value = { ...getSupportAgentResult };

    if (getSupportAgentResult.phone != null) {
      phoneTelNumberInputModel.value.value = '+' + getSupportAgentResult.phoneCountryCode + getSupportAgentResult.phone;
    } else {
      phoneTelNumberInputModel.value.value = '';
    }

    if (!isCreate.value) {
      const status = getSupportAgentResult.isDeactivated ? 'inactive' : 'active';
      selectedAccountStatus.value = accountStatuses.value.find(a => a.value === status);
    }

    nextTick(() => initialFormState.value = buildFormStateObject())

    nextTick(() => form.value.resetValidation())
  }
  else {
    const message = getSupportAgentProfileResponse?.data?.errorMessage || i18n.global.t('error_unexpected_occured').toString();
    $q.notify({
      message: message,
      type: 'warning'
    });
  }

  isLoading.value = false;
}
// #endregion Handlers

// #region Functions


// #endregion Functions

// #region Hooks + Watchers


onActivated(async () => {
  userDeactivated.value = false;
  await populateAgentData(agentId.value);
});

// #endregion Hooks + Watchers
</script>

<template>
  <q-page class="rep-profile" padding>
    <!-- Header -->
    <div class="row items-center rep-profile__header">
      <div class="col-auto"><zbm-btn-icon @click="onGoBack" class="q-mr-lg" color="primary-color" icon="far fa-angle-left" size="38px" /></div>
      <!-- Title -->
      <div class="col">
        <zbm-skeleton-loader height="2.3571528571rem" :loading="isLoading" width="100%">

          <h1 v-if="isCreate">{{ $t('create_support_profile') }}</h1>
          <h1 v-else>{{ $t('edit_support_profile') }}</h1>

        </zbm-skeleton-loader>
      </div>
    </div>

    <!-- Form -->
    <div class="row">
      <div class="col-auto bg-white q-pa-xl rep-profile__form">
        <q-form @submit.prevent ref="form">
          <div class="row mb-50 rep-profile__fields">

            <div class="col-12 col-md">
              <zbm-skeleton-loader height="22.5714285714rem" :loading="isLoading" width="100%">

                <!-- First -->
                <div class="row form-row items-center">
                  <div class="col-auto">{{$t('gen_first_name_initialCap')}}</div>
                  <div class="col">
                    <zbm-input-with-validation v-model="supportAgent.firstName"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull" :disable="disableFormFields"
                                               :rules="[
                                                 $zb.validators.required(),
                                                 $zb.validators.alphaZb(),
                                                 $zb.validators.maxLen(64),
                                                 $zb.validators.minLen(1)
                                               ]" />
                  </div>
                </div>

                <!-- Last -->
                <div class="row form-row items-center">
                  <div class="col-auto">{{$t('gen_last_name_initialCap')}}</div>
                  <div class="col">
                    <zbm-input-with-validation v-model="supportAgent.lastName"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull" :disable="disableFormFields"
                                               :rules="[
                                                 $zb.validators.required(),
                                                 $zb.validators.alphaZb(),
                                                 $zb.validators.maxLen(64),
                                                 $zb.validators.minLen(1)
                                               ]" />
                  </div>
                </div>

                <!-- Phone -->
                <div class="row form-row items-center">
                  <div class="col-auto">{{$t('gen_mobile_number_initialCap')}}</div>
                  <div class="col">
                    <zbm-telephone-with-validation v-model="phoneTelNumberInputModel" :disable="disableFormFields"
                                                   :input-width="$zb.enums.FormElWidthEnum.qFormElFull" :countryCode="supportAgent.phoneCountryCode || derivedCountryCode"
                                                   placeholder="(###) ###-####" :rules="[$zb.validators.required()]" />
                  </div>
                </div>

                <!-- Email -->
                <div class="row form-row items-center">
                  <div class="col-auto">{{$t('gen_label_email')}}</div>
                  <div class="col">
                    <zbm-input-with-validation v-model="supportAgent.email"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull" :disable="disableEmail || disableFormFields"
                                               :rules="[
                                                  $zb.validators.required(),
                                                  $zb.validators.maxLen(64),
                                                  $zb.validators.email()
                                              ]" type="email">
                    </zbm-input-with-validation>
                  </div>
                  <div v-if="disableEmail" class="col-auto cursor-pointer">
                    <q-icon name="fal fa-question-circle"
                            size="xs">
                      <q-tooltip class="text-body2">
                        {{$t('profile_email_not_editable')}}
                      </q-tooltip>
                    </q-icon>
                  </div>
                </div>

                <!-- Account Status -->
                <div class="row form-row items-center" v-if="!isCreate">
                  <div class="col-auto">{{ $t('gen_label_user_status') }}</div>
                  <div class="col">
                    <q-select outlined v-model="selectedAccountStatus" :options="accountStatuses" />
                  </div>
                </div>

                <!-- Permissions -->
                <div class="row form-row items-center">
                  <div class="col-auto">{{ $t('gen_label_admin_permissions') }}</div>
                  <div class="col">
                    <Toggle v-model="isManager" :disable="disableFormFields" />
                  </div>
                </div>

              </zbm-skeleton-loader>
            </div>
          </div>

          <!-- Form Buttons -->
          <div class="row items-center q-col-gutter-x-sm q-pt-xl rep-profile__buttons">

            <!-- Cancel -->
            <div class="col-auto">
              <zbm-skeleton-loader height="2.8571428571rem" :loading="isLoading" width="3.1428571429rem">
                <zbm-btn @click="onCancel" :color="$zb.enums.ButtonStdColorEnum.darkTealText"
                         :height="$zb.enums.ButtonHeightEnum.md"
                         :label="$t('gen_cancel')" :data-testid="'btnCancel'"/>
              </zbm-skeleton-loader>
            </div>

            <!-- Save -->
            <div class="col-auto">
              <zbm-skeleton-loader height="2.8571428571rem" :loading="isLoading" width="11.5714285714rem">
                <zbm-btn @click="onSubmit" :color="$zb.enums.ButtonStdColorEnum.darkTeal" :disabled="!isFormDirty"
                         :height="$zb.enums.ButtonHeightEnum.md" :label="$t('gen_save_profile')"
                         :type="$zb.enums.ButtonTypeEnum.submit" :data-testid="'btnSave'"/>
              </zbm-skeleton-loader>
            </div>
          </div>
        </q-form>
      </div>
    </div>
  </q-page>
</template>

<style scoped lang="scss">
@import "@/css/functions";

.rep-profile {

  // Header
  &__header {
    // .row
    margin-bottom: makeRem(32px);

  }

  &__form{
    $form-cont-width: 645px;

    border: 1px solid rgba($black, 0.12);
    border-radius: $generic-border-radius-sm;
    width: $form-cont-width;
    max-width: $form-cont-width;
    flex-basis: $form-cont-width;
  }

  // Form Fields
  &__fields {
    // .row

    >[class*="col"] {

    }

    // Name
    .name {
      // .form-row

      :deep(.q-field__bottom) {
        width: makeRem(150px);
      }
    }
  }

  // Form Buttons
  &__buttons {
    // .row
    justify-content: center;
    border-top: 1px solid rgba($black, 0.12);

    @media (min-width: $breakpoint-sm-min) {
      justify-content: flex-end;
    }
  }
}
</style>
